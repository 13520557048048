.navBox {
  width: 100%;
  display: -webkit-flex;
  justify-content: space-evenly;
  position: relative;
  margin-top: 2%;
  height: 10vh;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    height: 12vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8vh;
  }
}

.linkBox {
  text-decoration: none;
  font-family: "Montserrat", sans-serif;

  &:hover {
    text-decoration: none;
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff,
      0 0 80px #0ff, 0 0 90px rgb(255, 0, 191), 0 0 100px rgb(255, 0, 191),
      0 0 150px rgb(255, 0, 191);
  }

  .navText {
    cursor: pointer;
    @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
      font-size: 3em;
    }
  }
}

.arrowContain{
        width: 100vw;
        margin-top: 40vh;
        display: flex;
        justify-content: center;
        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
                margin-top: 30vh;
        }
}

.triangle{
  filter: invert(100);
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
        width: 300px;
        height: 300px;
        background-image: url("./../../assets/triangle.png");
        background-repeat: no-repeat;
        background-size: contain;
}

.triText{
  font-weight: bolder;
  text-align: center;
  


}

.rotate{
  transform: rotate(180deg);
}

.arrowBox {
        top: 0;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  
  
}

.invert{
  filter: invert(100);
}

.arrowImg {
  
}

.blackArrowBox {
  cursor: pointer;
  width: 100%;
  color: black;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100;
  width: 100%;
  margin-top: 15%;

  @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
    margin-bottom: 10vh;
  }
  @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
    margin-bottom: 5%;
    margin-top: 10%;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin-top: 5vh;
  }
}

.white {
  color: aliceblue;
  text-decoration: none;
}

.heartbeat {
	animation: heartbeat 6s ease-in-out both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}


.slide-in-top {
  -webkit-animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s
    both;
  animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.text-focus-in {
	-webkit-animation: text-focus-in 7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
