p{
    color: rgb(0, 0, 0);
}

.gradient{
    margin-top: 25vh;
    position: relative;
	width: 100%;
	height: 100%;
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    flex-wrap: wrap;
	justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), white);
    flex: 0 1 auto;
}

.gradientBlack{
    position: relative;
	width: 100%;
    background-image: linear-gradient(to bottom, white, black);
    padding-top: 15vh;
    padding-bottom: 15vh;
}

.gradientReverse{
    position: relative;
	width: 100%;
	height: 100%;
    background-image: linear-gradient(to top, rgba(255,0,0,0), black);
    padding-top: 10vh;
    padding-bottom: 10vh;
}

.howdyBox{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30vh;
    margin-bottom: 10vh;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        margin-top: 40vh;
        margin-bottom: 30vh;
        }

        @media only screen and (min-device-width : 1500px) and (max-device-width : 2100px) {
        margin-top: 40vh;
        }
}



.AboutBox{
    
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
    
}

.maxWidth{
    width: 100%;
}

.aboutBackdrop{
    display:-webkit-flex;
    flex-wrap: wrap;
    background-color: rgb(255, 255, 255);
    width: 100%;
    
}

.glitchBox{
    margin-top: 3%;
    
    display: flex;
    
    justify-self: center;
    height: 8%;
}

.firstBlock{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.heading{
    padding-top: 3vh;
    width: 100%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    
}

.Subheading{
    padding-top: 1vh;
    padding-bottom: 3vh;
    width: 100%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.blockBox{
    display: flex;
    width: 100%;
    padding-top: 5vh;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10vh;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        flex-direction: column;
        padding-top: 5vh;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 5vh;
    align-items: center;
        }
}


.meBoxLeft{
    width: 30%;
    margin-right: 5%;

    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        width: 100%;
        margin-bottom: 5vh;
        }
}

.meBoxRight{
    width: 30%;
    margin-left: 5%;
}

.meImg{
    width: 100%;
    text-align: center;
}

.description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        width: 100%;
        text-align: center;
        }
        @media only screen and (min-device-width : 1500px) and (max-device-width : 2100px) {
        font-size: 1.5em;
        }
}

.img{
    background-color: palevioletred;
    height: 90%;
    width: 90px;
}
