.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.reveal{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  height: 100%;
}

.loadBox{
  position: relative;
  
}

.slide-out-bck-center {
	-webkit-animation: slide-out-bck-center 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 8s both;
	        animation: slide-out-bck-center 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) 8s both;
}

@-webkit-keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
            transform: translateZ(-1100px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
            transform: translateZ(-1100px);
    opacity: 0;
  }
}


.rotate-out-center {
	-webkit-animation: rotate-out-center 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) 6s both;
	        animation: rotate-out-center 0.6s cubic-bezier(0.550, 0.085, 0.680, 0.530) 6s both;
}

@-webkit-keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
}
@keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
}



.lds-css{
  width: 100%;
  height: 100%
}

@keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.lds-double-ring {
  position: relative;
  width: 100%;
  height: 100%;
}
.lds-double-ring div {
  width: 100%;
  height: 100%;
}
.lds-double-ring > div {
  position: absolute;
  width: 168px;
  height: 168px;
  top: 16px;
  left: 16px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #623c65 transparent #623c65 transparent;
  -webkit-animation: lds-double-ring 1s linear infinite;
  animation: lds-double-ring 1s linear infinite;
}
.lds-double-ring > div:nth-child(2),
.lds-double-ring > div:nth-child(4) {
  width: 148px;
  height: 148px;
  top: 26px;
  left: 26px;
  -webkit-animation: lds-double-ring_reverse 1s linear infinite;
  animation: lds-double-ring_reverse 1s linear infinite;
}
.lds-double-ring > div:nth-child(2) {
  border-color: transparent #ffffff transparent #ffffff;
}
.lds-double-ring > div:nth-child(3) {
  border-color: transparent;
}
.lds-double-ring > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.lds-double-ring > div:nth-child(3) div:before,
.lds-double-ring > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 72px;
  background: #623c65;
  border-radius: 50%;
  box-shadow: 0 160px 0 0 #623c65;
}
.lds-double-ring > div:nth-child(3) div:after {
  left: -8px;
  top: 72px;
  box-shadow: 160px 0 0 0 #623c65;
}
.lds-double-ring > div:nth-child(4) {
  border-color: transparent;
}
.lds-double-ring > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.lds-double-ring > div:nth-child(4) div:before,
.lds-double-ring > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 62px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 140px 0 0 #ffffff;
}
.lds-double-ring > div:nth-child(4) div:after {
  left: -8px;
  top: 62px;
  box-shadow: 140px 0 0 0 #ffffff;
}
.lds-double-ring {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}