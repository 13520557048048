.contactBox{
	height: 100vh;
	width: 100%;
	display:-webkit-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

.contactHeader{
	margin-bottom: 20vh;
	margin-top: 20vh;
}

.contactLinks{
	display: flex;
	height: 10vh;
	width: 100%;
	justify-content: center;
	align-items: center;
	padding-bottom: 22vh;
}

.contactLinkBox{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5%;
	height: 100%;
}


.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-bottom {
	0% {
	  -webkit-transform: translateY(1000px);
			  transform: translateY(1000px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	  opacity: 1;
	}
  }
  @keyframes slide-in-bottom {
	0% {
	  -webkit-transform: translateY(1000px);
			  transform: translateY(1000px);
	  opacity: 0;
	}
	100% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	  opacity: 1;
	}
  }
  