.videobox {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 37, 40, 0.3);
  z-index: 1;
  overflow: hidden;
  
  
  
}

.austinLogo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#blackText{
  color: black;

  
}



.intro {
	background-color: white;
  width: 100%;
  padding-top: 15vh;
  padding-left: 30vh;
  padding-right: 30vh;
  padding-bottom: 15vh;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  flex-direction: column;
  align-items: center;
  
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding-left: 0vh;
    padding-right: 0vh;
    padding-bottom: 30vh;
    }

    
}

.introText{
  display: flex;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    margin-bottom: 5vh;
    }
  
    @media only screen and (min-device-width : 1500px) and (max-device-width : 2100px) {
        font-size: 4em;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
}


//glitch starts here
@import url("https://fonts.googleapis.com/css?family=Montserrat:100");

@mixin glitchCopy {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch {
  position: relative;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 4em;
  letter-spacing: 0.5;
  /* Animation provies a slight random skew. Check bottom of doc
	for more information on how to random skew. */
  animation: glitch-skew 1s infinite linear alternate-reverse;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 3em;
    }

  // Creates a copy before our text.
  &::before {
    // Duplicates our text with the mixin.
    @include glitchCopy;
    // Scoots text slightly to the left for the color offset.
    left: 2px;
    // Creates the color 'shadow' that happens on the glitch.
    text-shadow: -2px 0 #ff00c1;
    /* Creates an initial clip for our glitch. This works in
		a typical top,right,bottom,left fashion and creates a mask
		to only show a certain part of the glitch at a time. */
    clip: rect(44px, 450px, 56px, 0);
    /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
		with an alternating animation to keep things fresh. */
    animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  // Creates a copy after our text. Note comments from ::before.
  &::after {
    @include glitchCopy;
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch-anim2 1s infinite linear alternate-reverse;
  }
}

/* Creates an animation with 20 steaps. For each step, it calculates 
a percentage for the specific step. It then generates a random clip
box to be used for the random glitch effect. Also adds a very subtle
skew to change the 'thickness' of the glitch.*/
@keyframes glitch-anim {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

// Same deal, just duplicated for ultra randomness.
@keyframes glitch-anim2 {
  $steps: 20;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100) + px, 9999px, random(100) + px, 0);
      transform: skew((random(100) / 100) + deg);
    }
  }
}

// Does the same deal as before, but now only skews. Used for main text.
@keyframes glitch-skew {
  $steps: 10;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      transform: skew((random(10) - 5) + deg);
    }
  }
}

.flicker-in-2 {
  -webkit-animation: flicker-in-2 4s linear both;
  animation: flicker-in-2 4s linear both;
}

@-webkit-keyframes flicker-in-2 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  10.1% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  10.2% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  20% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  20.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  30% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  30.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  45% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  45.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  57% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  57.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
  }
  60% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
  }
  60.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  65% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  65.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  77% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  77.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  86% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  86.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
}
@keyframes flicker-in-2 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  10.1% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  10.2% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  20% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  20.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  30% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  30.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  45% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  45.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  57% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  57.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
  }
  60% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3);
  }
  60.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  65% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  65.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  77% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  77.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  86% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  86.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
}
