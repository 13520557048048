.portfolioPage{
	width: 100%;
  height: 100vh; 
  background-color: black;
}

h1{
    
}


.portfolioBackdrop{
  background-color: black;
  width: 100%;
  padding-bottom: 10vh;
  display:-webkit-flex;
  flex-direction: column;
  align-items: center;
}

.color-change-3x {
	-webkit-animation: color-change-3x 7s linear infinite alternate both;
            animation: color-change-3x 7s linear infinite alternate both;
            
}

.carContain{
    background-color: rgba($color: #000000, $alpha: 0.5);
    
    height: 85vh;
    width: 90vw;
    border-radius: 1%;
    display:-webkit-flex;
    justify-content: center;
    align-items: center;
}

.carSize{
    width: 100%;
    height: 100%;
}

.carousel-inner{
    height: 100%;
    width: 100%;
}

.carousel-caption{
  top: 30%;
  height: 20%;
}

.carHead{
  @media only screen and (min-device-width : 1500px) and (max-device-width : 2100px) {
        font-size: 4em;
  }
}

.carDesc{
  @media only screen and (min-device-width : 1500px) and (max-device-width : 2100px) {
        font-size: 2em;
  }
}

.itemSize{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.carLinkBox{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black;
  
}

.imageSize{
  width: 100%;
  height: 100%;
  opacity: 0.3;
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 50%;
    width: 100%;
  }

}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}







@-webkit-keyframes color-change-3x {
    0% {
      background: rgba($color: #000000, $alpha: .3);
    }
    50% {
      background: rgba($color: #f716f7, $alpha: .3);
    }
    100% {
      background: rgba($color: #0dcce6, $alpha: .3)
    }
  }
  @keyframes color-change-3x {
    0% {
      background: rgba($color: #000000, $alpha: .3);
    }
    50% {
      background: rgba($color: #f716f7, $alpha: .3);
    }
    100% {
      background: rgba($color: #0dcce6, $alpha: .3)
    }
  }
  