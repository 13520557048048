@import url(https://fonts.googleapis.com/css?family=Montserrat:100);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center; }

.App-logo {
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #09d3ac; }

.reveal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: black;
  width: 100%;
  height: 100%; }

.loadBox {
  position: relative; }

.slide-out-bck-center {
  -webkit-animation: slide-out-bck-center 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 8s both;
  animation: slide-out-bck-center 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 8s both; }

@-webkit-keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0; } }

@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0; } }

.rotate-out-center {
  -webkit-animation: rotate-out-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) 6s both;
  animation: rotate-out-center 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) 6s both; }

@-webkit-keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0; } }

@keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
    opacity: 0; } }

.lds-css {
  width: 100%;
  height: 100%; }

@keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

@-webkit-keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg); } }

.lds-double-ring {
  position: relative;
  width: 100%;
  height: 100%; }

.lds-double-ring div {
  width: 100%;
  height: 100%; }

.lds-double-ring > div {
  position: absolute;
  width: 168px;
  height: 168px;
  top: 16px;
  left: 16px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #623c65 transparent #623c65 transparent;
  -webkit-animation: lds-double-ring 1s linear infinite;
  animation: lds-double-ring 1s linear infinite; }

.lds-double-ring > div:nth-child(2),
.lds-double-ring > div:nth-child(4) {
  width: 148px;
  height: 148px;
  top: 26px;
  left: 26px;
  -webkit-animation: lds-double-ring_reverse 1s linear infinite;
  animation: lds-double-ring_reverse 1s linear infinite; }

.lds-double-ring > div:nth-child(2) {
  border-color: transparent #ffffff transparent #ffffff; }

.lds-double-ring > div:nth-child(3) {
  border-color: transparent; }

.lds-double-ring > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.lds-double-ring > div:nth-child(3) div:before,
.lds-double-ring > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 72px;
  background: #623c65;
  border-radius: 50%;
  box-shadow: 0 160px 0 0 #623c65; }

.lds-double-ring > div:nth-child(3) div:after {
  left: -8px;
  top: 72px;
  box-shadow: 160px 0 0 0 #623c65; }

.lds-double-ring > div:nth-child(4) {
  border-color: transparent; }

.lds-double-ring > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.lds-double-ring > div:nth-child(4) div:before,
.lds-double-ring > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 62px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 140px 0 0 #ffffff; }

.lds-double-ring > div:nth-child(4) div:after {
  left: -8px;
  top: 62px;
  box-shadow: 140px 0 0 0 #ffffff; }

.lds-double-ring {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px); }

.videobox {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.video {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 37, 40, 0.3);
  z-index: 1;
  overflow: hidden; }

.austinLogo {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

#blackText {
  color: black; }

.intro {
  background-color: white;
  width: 100%;
  padding-top: 15vh;
  padding-left: 30vh;
  padding-right: 30vh;
  padding-bottom: 15vh;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  /* TWEENER - IE 10 */
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  flex-direction: column;
  align-items: center; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .intro {
      padding-left: 0vh;
      padding-right: 0vh;
      padding-bottom: 30vh; } }

.introText {
  display: flex;
  text-align: center;
  font-family: "Montserrat", sans-serif; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .introText {
      margin-bottom: 5vh; } }
  @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
    .introText {
      font-size: 4em;
      margin-top: 5vh;
      margin-bottom: 5vh; } }

.glitch {
  position: relative;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 4em;
  letter-spacing: 0.5;
  /* Animation provies a slight random skew. Check bottom of doc
	for more information on how to random skew. */
  animation: glitch-skew 1s infinite linear alternate-reverse; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .glitch {
      font-size: 3em; } }
  .glitch::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    text-shadow: -2px 0 #ff00c1;
    /* Creates an initial clip for our glitch. This works in
		a typical top,right,bottom,left fashion and creates a mask
		to only show a certain part of the glitch at a time. */
    clip: rect(44px, 450px, 56px, 0);
    /* Runs our glitch-anim defined below to run in a 5s loop, infinitely,
		with an alternating animation to keep things fresh. */
    animation: glitch-anim 5s infinite linear alternate-reverse; }
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    left: -2px;
    text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
    animation: glitch-anim2 1s infinite linear alternate-reverse; }

/* Creates an animation with 20 steaps. For each step, it calculates 
a percentage for the specific step. It then generates a random clip
box to be used for the random glitch effect. Also adds a very subtle
skew to change the 'thickness' of the glitch.*/
@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(65px, 9999px, 22px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  5% {
    clip: rect(58px, 9999px, 94px, 0);
    -webkit-transform: skew(0.91deg);
            transform: skew(0.91deg); }
  10% {
    clip: rect(76px, 9999px, 94px, 0);
    -webkit-transform: skew(0.97deg);
            transform: skew(0.97deg); }
  15% {
    clip: rect(82px, 9999px, 20px, 0);
    -webkit-transform: skew(0.17deg);
            transform: skew(0.17deg); }
  20% {
    clip: rect(30px, 9999px, 80px, 0);
    -webkit-transform: skew(0.66deg);
            transform: skew(0.66deg); }
  25% {
    clip: rect(52px, 9999px, 37px, 0);
    -webkit-transform: skew(0.81deg);
            transform: skew(0.81deg); }
  30% {
    clip: rect(98px, 9999px, 75px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg); }
  35% {
    clip: rect(18px, 9999px, 25px, 0);
    -webkit-transform: skew(0.47deg);
            transform: skew(0.47deg); }
  40% {
    clip: rect(10px, 9999px, 73px, 0);
    -webkit-transform: skew(0.1deg);
            transform: skew(0.1deg); }
  45% {
    clip: rect(61px, 9999px, 56px, 0);
    -webkit-transform: skew(0.07deg);
            transform: skew(0.07deg); }
  50% {
    clip: rect(10px, 9999px, 83px, 0);
    -webkit-transform: skew(0.33deg);
            transform: skew(0.33deg); }
  55% {
    clip: rect(59px, 9999px, 81px, 0);
    -webkit-transform: skew(0.37deg);
            transform: skew(0.37deg); }
  60% {
    clip: rect(58px, 9999px, 50px, 0);
    -webkit-transform: skew(0.16deg);
            transform: skew(0.16deg); }
  65% {
    clip: rect(16px, 9999px, 93px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  70% {
    clip: rect(60px, 9999px, 55px, 0);
    -webkit-transform: skew(0.84deg);
            transform: skew(0.84deg); }
  75% {
    clip: rect(5px, 9999px, 72px, 0);
    -webkit-transform: skew(0.18deg);
            transform: skew(0.18deg); }
  80% {
    clip: rect(54px, 9999px, 6px, 0);
    -webkit-transform: skew(0.29deg);
            transform: skew(0.29deg); }
  85% {
    clip: rect(6px, 9999px, 40px, 0);
    -webkit-transform: skew(0.44deg);
            transform: skew(0.44deg); }
  90% {
    clip: rect(46px, 9999px, 4px, 0);
    -webkit-transform: skew(0.97deg);
            transform: skew(0.97deg); }
  95% {
    clip: rect(85px, 9999px, 96px, 0);
    -webkit-transform: skew(0.22deg);
            transform: skew(0.22deg); }
  100% {
    clip: rect(41px, 9999px, 96px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); } }
@keyframes glitch-anim {
  0% {
    clip: rect(65px, 9999px, 22px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  5% {
    clip: rect(58px, 9999px, 94px, 0);
    -webkit-transform: skew(0.91deg);
            transform: skew(0.91deg); }
  10% {
    clip: rect(76px, 9999px, 94px, 0);
    -webkit-transform: skew(0.97deg);
            transform: skew(0.97deg); }
  15% {
    clip: rect(82px, 9999px, 20px, 0);
    -webkit-transform: skew(0.17deg);
            transform: skew(0.17deg); }
  20% {
    clip: rect(30px, 9999px, 80px, 0);
    -webkit-transform: skew(0.66deg);
            transform: skew(0.66deg); }
  25% {
    clip: rect(52px, 9999px, 37px, 0);
    -webkit-transform: skew(0.81deg);
            transform: skew(0.81deg); }
  30% {
    clip: rect(98px, 9999px, 75px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg); }
  35% {
    clip: rect(18px, 9999px, 25px, 0);
    -webkit-transform: skew(0.47deg);
            transform: skew(0.47deg); }
  40% {
    clip: rect(10px, 9999px, 73px, 0);
    -webkit-transform: skew(0.1deg);
            transform: skew(0.1deg); }
  45% {
    clip: rect(61px, 9999px, 56px, 0);
    -webkit-transform: skew(0.07deg);
            transform: skew(0.07deg); }
  50% {
    clip: rect(10px, 9999px, 83px, 0);
    -webkit-transform: skew(0.33deg);
            transform: skew(0.33deg); }
  55% {
    clip: rect(59px, 9999px, 81px, 0);
    -webkit-transform: skew(0.37deg);
            transform: skew(0.37deg); }
  60% {
    clip: rect(58px, 9999px, 50px, 0);
    -webkit-transform: skew(0.16deg);
            transform: skew(0.16deg); }
  65% {
    clip: rect(16px, 9999px, 93px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  70% {
    clip: rect(60px, 9999px, 55px, 0);
    -webkit-transform: skew(0.84deg);
            transform: skew(0.84deg); }
  75% {
    clip: rect(5px, 9999px, 72px, 0);
    -webkit-transform: skew(0.18deg);
            transform: skew(0.18deg); }
  80% {
    clip: rect(54px, 9999px, 6px, 0);
    -webkit-transform: skew(0.29deg);
            transform: skew(0.29deg); }
  85% {
    clip: rect(6px, 9999px, 40px, 0);
    -webkit-transform: skew(0.44deg);
            transform: skew(0.44deg); }
  90% {
    clip: rect(46px, 9999px, 4px, 0);
    -webkit-transform: skew(0.97deg);
            transform: skew(0.97deg); }
  95% {
    clip: rect(85px, 9999px, 96px, 0);
    -webkit-transform: skew(0.22deg);
            transform: skew(0.22deg); }
  100% {
    clip: rect(41px, 9999px, 96px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); } }

@-webkit-keyframes glitch-anim2 {
  0% {
    clip: rect(54px, 9999px, 14px, 0);
    -webkit-transform: skew(0.87deg);
            transform: skew(0.87deg); }
  5% {
    clip: rect(7px, 9999px, 49px, 0);
    -webkit-transform: skew(0.5deg);
            transform: skew(0.5deg); }
  10% {
    clip: rect(96px, 9999px, 29px, 0);
    -webkit-transform: skew(0.75deg);
            transform: skew(0.75deg); }
  15% {
    clip: rect(7px, 9999px, 92px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  20% {
    clip: rect(25px, 9999px, 93px, 0);
    -webkit-transform: skew(0.49deg);
            transform: skew(0.49deg); }
  25% {
    clip: rect(74px, 9999px, 28px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  30% {
    clip: rect(11px, 9999px, 59px, 0);
    -webkit-transform: skew(0.38deg);
            transform: skew(0.38deg); }
  35% {
    clip: rect(7px, 9999px, 46px, 0);
    -webkit-transform: skew(0.67deg);
            transform: skew(0.67deg); }
  40% {
    clip: rect(56px, 9999px, 5px, 0);
    -webkit-transform: skew(0.09deg);
            transform: skew(0.09deg); }
  45% {
    clip: rect(77px, 9999px, 94px, 0);
    -webkit-transform: skew(0.94deg);
            transform: skew(0.94deg); }
  50% {
    clip: rect(47px, 9999px, 63px, 0);
    -webkit-transform: skew(0.66deg);
            transform: skew(0.66deg); }
  55% {
    clip: rect(59px, 9999px, 86px, 0);
    -webkit-transform: skew(0.46deg);
            transform: skew(0.46deg); }
  60% {
    clip: rect(48px, 9999px, 5px, 0);
    -webkit-transform: skew(0.16deg);
            transform: skew(0.16deg); }
  65% {
    clip: rect(3px, 9999px, 27px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg); }
  70% {
    clip: rect(16px, 9999px, 31px, 0);
    -webkit-transform: skew(0.59deg);
            transform: skew(0.59deg); }
  75% {
    clip: rect(86px, 9999px, 90px, 0);
    -webkit-transform: skew(0.29deg);
            transform: skew(0.29deg); }
  80% {
    clip: rect(53px, 9999px, 21px, 0);
    -webkit-transform: skew(0.35deg);
            transform: skew(0.35deg); }
  85% {
    clip: rect(86px, 9999px, 42px, 0);
    -webkit-transform: skew(0.13deg);
            transform: skew(0.13deg); }
  90% {
    clip: rect(44px, 9999px, 25px, 0);
    -webkit-transform: skew(0.8deg);
            transform: skew(0.8deg); }
  95% {
    clip: rect(83px, 9999px, 92px, 0);
    -webkit-transform: skew(0.2deg);
            transform: skew(0.2deg); }
  100% {
    clip: rect(18px, 9999px, 72px, 0);
    -webkit-transform: skew(0.78deg);
            transform: skew(0.78deg); } }

@keyframes glitch-anim2 {
  0% {
    clip: rect(54px, 9999px, 14px, 0);
    -webkit-transform: skew(0.87deg);
            transform: skew(0.87deg); }
  5% {
    clip: rect(7px, 9999px, 49px, 0);
    -webkit-transform: skew(0.5deg);
            transform: skew(0.5deg); }
  10% {
    clip: rect(96px, 9999px, 29px, 0);
    -webkit-transform: skew(0.75deg);
            transform: skew(0.75deg); }
  15% {
    clip: rect(7px, 9999px, 92px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  20% {
    clip: rect(25px, 9999px, 93px, 0);
    -webkit-transform: skew(0.49deg);
            transform: skew(0.49deg); }
  25% {
    clip: rect(74px, 9999px, 28px, 0);
    -webkit-transform: skew(0.98deg);
            transform: skew(0.98deg); }
  30% {
    clip: rect(11px, 9999px, 59px, 0);
    -webkit-transform: skew(0.38deg);
            transform: skew(0.38deg); }
  35% {
    clip: rect(7px, 9999px, 46px, 0);
    -webkit-transform: skew(0.67deg);
            transform: skew(0.67deg); }
  40% {
    clip: rect(56px, 9999px, 5px, 0);
    -webkit-transform: skew(0.09deg);
            transform: skew(0.09deg); }
  45% {
    clip: rect(77px, 9999px, 94px, 0);
    -webkit-transform: skew(0.94deg);
            transform: skew(0.94deg); }
  50% {
    clip: rect(47px, 9999px, 63px, 0);
    -webkit-transform: skew(0.66deg);
            transform: skew(0.66deg); }
  55% {
    clip: rect(59px, 9999px, 86px, 0);
    -webkit-transform: skew(0.46deg);
            transform: skew(0.46deg); }
  60% {
    clip: rect(48px, 9999px, 5px, 0);
    -webkit-transform: skew(0.16deg);
            transform: skew(0.16deg); }
  65% {
    clip: rect(3px, 9999px, 27px, 0);
    -webkit-transform: skew(0.93deg);
            transform: skew(0.93deg); }
  70% {
    clip: rect(16px, 9999px, 31px, 0);
    -webkit-transform: skew(0.59deg);
            transform: skew(0.59deg); }
  75% {
    clip: rect(86px, 9999px, 90px, 0);
    -webkit-transform: skew(0.29deg);
            transform: skew(0.29deg); }
  80% {
    clip: rect(53px, 9999px, 21px, 0);
    -webkit-transform: skew(0.35deg);
            transform: skew(0.35deg); }
  85% {
    clip: rect(86px, 9999px, 42px, 0);
    -webkit-transform: skew(0.13deg);
            transform: skew(0.13deg); }
  90% {
    clip: rect(44px, 9999px, 25px, 0);
    -webkit-transform: skew(0.8deg);
            transform: skew(0.8deg); }
  95% {
    clip: rect(83px, 9999px, 92px, 0);
    -webkit-transform: skew(0.2deg);
            transform: skew(0.2deg); }
  100% {
    clip: rect(18px, 9999px, 72px, 0);
    -webkit-transform: skew(0.78deg);
            transform: skew(0.78deg); } }

@-webkit-keyframes glitch-skew {
  0% {
    -webkit-transform: skew(1deg);
            transform: skew(1deg); }
  10% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg); }
  20% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg); }
  30% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg); }
  40% {
    -webkit-transform: skew(-2deg);
            transform: skew(-2deg); }
  50% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg); }
  60% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg); }
  70% {
    -webkit-transform: skew(-4deg);
            transform: skew(-4deg); }
  80% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg); }
  90% {
    -webkit-transform: skew(1deg);
            transform: skew(1deg); }
  100% {
    -webkit-transform: skew(3deg);
            transform: skew(3deg); } }

@keyframes glitch-skew {
  0% {
    -webkit-transform: skew(1deg);
            transform: skew(1deg); }
  10% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg); }
  20% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg); }
  30% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg); }
  40% {
    -webkit-transform: skew(-2deg);
            transform: skew(-2deg); }
  50% {
    -webkit-transform: skew(-3deg);
            transform: skew(-3deg); }
  60% {
    -webkit-transform: skew(2deg);
            transform: skew(2deg); }
  70% {
    -webkit-transform: skew(-4deg);
            transform: skew(-4deg); }
  80% {
    -webkit-transform: skew(-1deg);
            transform: skew(-1deg); }
  90% {
    -webkit-transform: skew(1deg);
            transform: skew(1deg); }
  100% {
    -webkit-transform: skew(3deg);
            transform: skew(3deg); } }

.flicker-in-2 {
  -webkit-animation: flicker-in-2 4s linear both;
  animation: flicker-in-2 4s linear both; }

@-webkit-keyframes flicker-in-2 {
  0% {
    opacity: 0; }
  10% {
    opacity: 0;
    box-shadow: none; }
  10.1% {
    opacity: 1;
    box-shadow: none; }
  10.2% {
    opacity: 0;
    box-shadow: none; }
  20% {
    opacity: 0;
    box-shadow: none; }
  20.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.25); }
  20.6% {
    opacity: 0;
    box-shadow: none; }
  30% {
    opacity: 0;
    box-shadow: none; }
  30.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  30.5% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  30.6% {
    opacity: 0;
    box-shadow: none; }
  45% {
    opacity: 0;
    box-shadow: none; }
  45.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  50% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  55% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  55.1% {
    opacity: 0;
    box-shadow: none; }
  57% {
    opacity: 0;
    box-shadow: none; }
  57.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3); }
  60% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3); }
  60.1% {
    opacity: 0;
    box-shadow: none; }
  65% {
    opacity: 0;
    box-shadow: none; }
  65.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1); }
  75% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1); }
  75.1% {
    opacity: 0;
    box-shadow: none; }
  77% {
    opacity: 0;
    box-shadow: none; }
  77.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1); }
  85% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1); }
  85.1% {
    opacity: 0;
    box-shadow: none; }
  86% {
    opacity: 0;
    box-shadow: none; }
  86.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1); } }

@keyframes flicker-in-2 {
  0% {
    opacity: 0; }
  10% {
    opacity: 0;
    box-shadow: none; }
  10.1% {
    opacity: 1;
    box-shadow: none; }
  10.2% {
    opacity: 0;
    box-shadow: none; }
  20% {
    opacity: 0;
    box-shadow: none; }
  20.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.25); }
  20.6% {
    opacity: 0;
    box-shadow: none; }
  30% {
    opacity: 0;
    box-shadow: none; }
  30.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  30.5% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  30.6% {
    opacity: 0;
    box-shadow: none; }
  45% {
    opacity: 0;
    box-shadow: none; }
  45.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  50% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  55% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25); }
  55.1% {
    opacity: 0;
    box-shadow: none; }
  57% {
    opacity: 0;
    box-shadow: none; }
  57.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3); }
  60% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3); }
  60.1% {
    opacity: 0;
    box-shadow: none; }
  65% {
    opacity: 0;
    box-shadow: none; }
  65.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1); }
  75% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.3), 0 0 100px rgba(255, 255, 255, 0.1); }
  75.1% {
    opacity: 0;
    box-shadow: none; }
  77% {
    opacity: 0;
    box-shadow: none; }
  77.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1); }
  85% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1); }
  85.1% {
    opacity: 0;
    box-shadow: none; }
  86% {
    opacity: 0;
    box-shadow: none; }
  86.1% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1); }
  100% {
    opacity: 1;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1); } }

.portfolioPage {
  width: 100%;
  height: 100vh;
  background-color: black; }

.portfolioBackdrop {
  background-color: black;
  width: 100%;
  padding-bottom: 10vh;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center; }

.color-change-3x {
  -webkit-animation: color-change-3x 7s linear infinite alternate both;
  animation: color-change-3x 7s linear infinite alternate both; }

.carContain {
  background-color: rgba(0, 0, 0, 0.5);
  height: 85vh;
  width: 90vw;
  border-radius: 1%;
  display: -webkit-flex;
  justify-content: center;
  align-items: center; }

.carSize {
  width: 100%;
  height: 100%; }

.carousel-inner {
  height: 100%;
  width: 100%; }

.carousel-caption {
  top: 30%;
  height: 20%; }

@media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
  .carHead {
    font-size: 4em; } }

@media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
  .carDesc {
    font-size: 2em; } }

.itemSize {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

.carLinkBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: black; }

.imageSize {
  width: 100%;
  height: 100%;
  opacity: 0.3; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .imageSize {
      height: 50%;
      width: 100%; } }

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0; }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1; } }

@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0; }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1; } }

@-webkit-keyframes color-change-3x {
  0% {
    background: rgba(0, 0, 0, 0.3); }
  50% {
    background: rgba(247, 22, 247, 0.3); }
  100% {
    background: rgba(13, 204, 230, 0.3); } }

@keyframes color-change-3x {
  0% {
    background: rgba(0, 0, 0, 0.3); }
  50% {
    background: rgba(247, 22, 247, 0.3); }
  100% {
    background: rgba(13, 204, 230, 0.3); } }

.navBox {
  width: 100%;
  display: -webkit-flex;
  justify-content: space-evenly;
  position: relative;
  margin-top: 2%;
  height: 10vh; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .navBox {
      height: 12vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 8vh; } }

.linkBox {
  text-decoration: none;
  font-family: "Montserrat", sans-serif; }
  .linkBox:hover {
    text-decoration: none;
    color: #fff;
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #0ff, 0 0 80px #0ff, 0 0 90px #ff00bf, 0 0 100px #ff00bf, 0 0 150px #ff00bf; }
  .linkBox .navText {
    cursor: pointer; }
    @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
      .linkBox .navText {
        font-size: 3em; } }

.arrowContain {
  width: 100vw;
  margin-top: 40vh;
  display: flex;
  justify-content: center; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .arrowContain {
      margin-top: 30vh; } }

.triangle {
  -webkit-filter: invert(100);
          filter: invert(100);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: flex;
  justify-content: center;
  width: 300px;
  height: 300px;
  background-image: url(/static/media/triangle.29ffe32a.png);
  background-repeat: no-repeat;
  background-size: contain; }

.triText {
  font-weight: bolder;
  text-align: center; }

.rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.arrowBox {
  top: 0;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif; }

.invert {
  -webkit-filter: invert(100);
          filter: invert(100); }

.blackArrowBox {
  cursor: pointer;
  width: 100%;
  color: black;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100;
  width: 100%;
  margin-top: 15%; }
  @media only screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
    .blackArrowBox {
      margin-bottom: 10vh; } }
  @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
    .blackArrowBox {
      margin-bottom: 5%;
      margin-top: 10%; } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .blackArrowBox {
      margin-top: 5vh; } }

.white {
  color: aliceblue;
  text-decoration: none; }

.heartbeat {
  -webkit-animation: heartbeat 6s ease-in-out both;
          animation: heartbeat 6s ease-in-out both; }

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; } }

.slide-in-top {
  -webkit-animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s both;
  animation: slide-in-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 4s both; }

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.text-focus-in {
  -webkit-animation: text-focus-in 7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; }

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

p {
  color: black; }

.gradient {
  margin-top: 25vh;
  position: relative;
  width: 100%;
  height: 100%;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  /* TWEENER - IE 10 */
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 0, 0, 0)), to(white));
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), white);
  flex: 0 1 auto; }

.gradientBlack {
  position: relative;
  width: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(white), to(black));
  background-image: linear-gradient(to bottom, white, black);
  padding-top: 15vh;
  padding-bottom: 15vh; }

.gradientReverse {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 0, 0, 0)), to(black));
  background-image: linear-gradient(to top, rgba(255, 0, 0, 0), black);
  padding-top: 10vh;
  padding-bottom: 10vh; }

.howdyBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  margin-bottom: 10vh; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .howdyBox {
      margin-top: 40vh;
      margin-bottom: 30vh; } }
  @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
    .howdyBox {
      margin-top: 40vh; } }

.AboutBox {
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll; }

.maxWidth {
  width: 100%; }

.aboutBackdrop {
  display: -webkit-flex;
  flex-wrap: wrap;
  background-color: white;
  width: 100%; }

.glitchBox {
  margin-top: 3%;
  display: flex;
  justify-self: center;
  height: 8%; }

.firstBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.heading {
  padding-top: 3vh;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif; }

.Subheading {
  padding-top: 1vh;
  padding-bottom: 3vh;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif; }

.blockBox {
  display: flex;
  width: 100%;
  padding-top: 5vh;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10vh; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .blockBox {
      flex-direction: column;
      padding-top: 5vh;
      padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 5vh;
      align-items: center; } }

.meBoxLeft {
  width: 30%;
  margin-right: 5%; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .meBoxLeft {
      width: 100%;
      margin-bottom: 5vh; } }

.meBoxRight {
  width: 30%;
  margin-left: 5%; }

.meImg {
  width: 100%;
  text-align: center; }

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%; }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .description {
      width: 100%;
      text-align: center; } }
  @media only screen and (min-device-width: 1500px) and (max-device-width: 2100px) {
    .description {
      font-size: 1.5em; } }

.img {
  background-color: palevioletred;
  height: 90%;
  width: 90px; }

.contactBox {
  height: 100vh;
  width: 100%;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.contactHeader {
  margin-bottom: 20vh;
  margin-top: 20vh; }

.contactLinks {
  display: flex;
  height: 10vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 22vh; }

.contactLinkBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
  height: 100%; }

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.headerBox {
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  height: 33.3vh; }

.portfolioBackground {
  cursor: pointer;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/Find.fcd7de4d.png);
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: padding 2s;
  transition: padding 2s; }
  .portfolioBackground:hover {
    -webkit-transition: padding 2s;
    transition: padding 2s;
    padding-top: 5vh; }

.portfolioBackground2 {
  cursor: pointer;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/mePic.53d29470.jpg);
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: padding 2s;
  transition: padding 2s; }
  .portfolioBackground2:hover {
    -webkit-transition: padding 2s;
    transition: padding 2s;
    padding-top: 5vh; }

.portfolioBackground3 {
  cursor: pointer;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  background-image: url(/static/media/dev.9f7d5922.png);
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: padding 2s;
  transition: padding 2s; }
  .portfolioBackground3:hover {
    -webkit-transition: padding 2s;
    transition: padding 2s;
    padding-top: 5vh; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .twoEm {
    width: 100%;
    font-size: 2.5em; } }

